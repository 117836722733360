import { DateTime } from 'luxon';
import { getAppSettings, MaintenanceRoute } from '../config';

export const maintenanceService = () => {
    const {
        message: maintenanceMessage,
        startDateTime,
        endDateTime,
        routeMessages,
    } = getAppSettings()?.maintenanceSettings || {};
    const parseJSONRoutes = (): MaintenanceRoute[] | null => {
        try {
            return JSON.parse(routeMessages);
        } catch (e) {
            console.log('Error parsing maintenance route messages');
            return null;
        }
    };
    const parsedMaintenanceRoutes = parseJSONRoutes();

    const getMaintenanceRoute = (route: string): MaintenanceRoute | null => {
        const routes = parsedMaintenanceRoutes;
        return routes ? routes.find((r: MaintenanceRoute) => r.route === route) ?? null : null;
    };

    const isUnderMaintenance = (route?: string): boolean => {
        const hasMessage = maintenanceMessage?.trim().length > 0;
        const maintenanceStartDateTime = (startDateTime ?? '').trim().length > 0 ? startDateTime : null;
        const maintenanceEndDateTime = (endDateTime ?? '').trim().length > 0 ? endDateTime : null;
        const hasRouteMessage = !!route && !!getMaintenanceRoute(route);

        if ((hasMessage || hasRouteMessage) && maintenanceStartDateTime && maintenanceEndDateTime) {
            const serverStartDateTime = DateTime.fromISO(maintenanceStartDateTime, { setZone: true });
            const serverEndDateTime = DateTime.fromISO(maintenanceEndDateTime, { setZone: true });
            const serverDateTimeNow = DateTime.now().setZone(serverEndDateTime.zone);
            return serverDateTimeNow >= serverStartDateTime && serverDateTimeNow <= serverEndDateTime;
        }
        return hasMessage || hasRouteMessage;
    };
    const getMaintenanceMessage = (route: string): string => {
        const routeMessage = getMaintenanceRoute(route);
        if (routeMessage) {
            return routeMessage.message;
        }
        return maintenanceMessage;
    };

    return {
        maintenanceMessage,
        isUnderMaintenance,
        getMaintenanceMessage,
    };
};
