import { FunctionComponent } from 'react';
import { Card } from 'primereact/card';
import './maintenance.scss';

export type MaintenanceProps = {
    message: string;
};

const Maintenance: FunctionComponent<MaintenanceProps> = ({ message }) => {
    return (
        <div className="maintenance-container">
            <Card>
                <h3>
                    <i className="pi pi-exclamation-triangle" style={{ fontSize: '1rem' }} />
                    System Maintenance
                </h3>
                <p>{message}</p>
            </Card>
        </div>
    );
};

export default Maintenance;
