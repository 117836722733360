import './conversation-center.scss';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as QueryString from 'query-string';
import { Loader } from 'concert-ui-library';
import { BreadCrumb } from 'primereact/breadcrumb';
import { MenuItem, MenuItemCommandEvent } from 'primereact/menuitem';
import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { MessageForm } from './message-sender';
import { ConversationViewer } from './conversation-viewer';
import { NotificationContext } from '../../notification-context';
import {
    Conversation,
    ConversationInput,
    SendConversationMessageInput,
    useCreateConversationMutation,
    useLazyGetConversationQuery,
    useMarkConversationMessagesOpenedMutation,
    useSendConversationMessageMutation,
} from '../../services/graphql/generated';
import { MESSAGE_SUCCESSFULLY_SUBMITTED, RE_ENGAGING_MESSAGE, ROUTE_PARAMS, ROUTES } from '../../constants';
import { MESSAGE_NOT_SAVED, SECURE_MESSAGES_UNAVAILABE } from './constants';
import { selectPatientContextData } from '../notification-preferences/selector';
import { isConcertGraphqlValidationError } from '../../error-type-check';

interface ConversationState {
    conversation?: Conversation | null;
}

export const ConversationCenter: FunctionComponent = () => {
    const patientContextState = useSelector(selectPatientContextData);
    const currentLocation = useLocation();
    const { state } = currentLocation as { state: ConversationState };
    const history = useHistory();
    const notificationContext = useContext(NotificationContext);
    const { conversationId, patientId } = QueryString.parse(currentLocation.search);
    const [conversationState, setConversationState] = useState<Conversation | null>(null);
    const [sendMessageFormData, setSendMessageFormData] = useState<MessageForm | null>(null);
    const [conversationQuery, { data: conversation, error: conversationError, isFetching }] =
        useLazyGetConversationQuery();
    const [
        createConversation,
        {
            isLoading: isCreationInProgress,
            isSuccess: creationSucceeded,
            error: creationError,
            data: createConversationResponse,
        },
    ] = useCreateConversationMutation();
    const [markConversationAsOpened] = useMarkConversationMessagesOpenedMutation();

    const [
        sendMessage,
        {
            data: sendMessageResponse,
            error: sendMessageError,
            isSuccess: messageReplySucceeded,
            isLoading: isMessageSendingInProgress,
        },
    ] = useSendConversationMessageMutation();
    const isFormVisible = conversationId === undefined;
    const patientCanSendAndReceiveMessages = (): boolean =>
        conversation?.getConversation?.patientCommunicationInformation?.canReceiveCommunications === true &&
        conversation?.getConversation?.patientCommunicationInformation?.patientCanSendMessage === true;
    const isReplyAllowed = () => {
        return (
            patientContextState.activeEpisodeOwnerId === conversation?.getConversation?.conversation?.clinicianId &&
            patientCanSendAndReceiveMessages()
        );
    };
    const isFormDisabled = conversationState !== null && !isReplyAllowed();
    const [showForm, setShowForm] = useState<boolean>((isFormVisible && !isFormDisabled) || !!sendMessageFormData);

    const isNewConversation = !conversationId;
    const isBusy = () => isFetching || isCreationInProgress || isMessageSendingInProgress;

    useEffect(() => {
        const getConversation = async () => {
            const payload = await conversationQuery({
                request: {
                    conversationId: conversationId as string,
                },
            }).unwrap();

            const latestMessageCreatedDateUtc =
                payload?.getConversation?.conversation?.messages?.[0]?.createDate ?? null;
            if (latestMessageCreatedDateUtc) {
                await markConversationAsOpened({
                    input: {
                        conversationId: conversationId as string,
                        lastOpenedMessageCreateDate: latestMessageCreatedDateUtc,
                    },
                });
            }
        };
        if (conversationId && !isFetching && !conversation && !conversationError) {
            getConversation();
        }
    }, []);

    const handleConversationCreation = async (formData: MessageForm) => {
        const creationInput: ConversationInput = {
            episodeId: formData.episode?.id as string,
            message: {
                message: formData.message,
                recipientId: (formData.episode?.patientId as string) ?? (formData.episode?.clinicianId as string),
            },
            topic: formData.topic as string,
        };
        setSendMessageFormData(formData);
        await createConversation({ input: creationInput });
    };
    const handleMessageSending = async (formData: MessageForm) => {
        const clinicianId = conversation?.getConversation?.conversation?.clinicianId;
        if (!clinicianId) return;

        const inputMessage: SendConversationMessageInput = {
            conversationId: conversationId as string,
            message: {
                message: formData.message,
                recipientId: clinicianId,
            },
        };
        setSendMessageFormData(formData);
        await sendMessage({ input: inputMessage });
    };

    const handleMessageSubmission = (formData: MessageForm) => {
        if (formData.isValid) {
            isNewConversation ? handleConversationCreation(formData) : handleMessageSending(formData);
        } else {
            notificationContext?.showError('Please enter a message');
        }
    };

    const goHome = (_: MenuItemCommandEvent): void => {
        history.push(`${ROUTES.MESSAGES}?${ROUTE_PARAMS.PATIENT_ID}=${patientId}`, {
            refresh: true,
        });
    };

    const getHomeBreadcrumb = (): MenuItem => {
        return {
            id: 'home',
            label: 'Messages',
            command: goHome,
        };
    };

    const getConversationTopicFromLocationState = (): string | null => {
        if (conversationState) {
            return conversationState?.topic ?? '';
        }

        if (state?.conversation) {
            return String(state.conversation.topic) ?? '';
        }

        return null;
    };

    const getNavigationModel = (): MenuItem[] => {
        const navigationModel = new Array<MenuItem>();
        const conversationTopic = getConversationTopicFromLocationState();

        if (conversationTopic) {
            navigationModel.push({
                id: 'conversation',
                label: conversationTopic,
                className: 'unclickable active',
            });
        }

        return navigationModel;
    };

    useEffect(() => {
        const communicationInfo = conversation?.getConversation?.patientCommunicationInformation;
        if (communicationInfo && !patientCanSendAndReceiveMessages()) {
            notificationContext?.showWarning(
                !communicationInfo.hasActiveEpisodeOwner || !communicationInfo.hasActiveEpisode
                    ? RE_ENGAGING_MESSAGE
                    : SECURE_MESSAGES_UNAVAILABE,
            );
        }
    }, [conversation]);

    useEffect(() => {
        if (conversationError) {
            notificationContext?.showError(SECURE_MESSAGES_UNAVAILABE);
            return;
        }
        if (creationError || sendMessageError) {
            notificationContext?.showError(MESSAGE_NOT_SAVED);
        }
    }, [conversation, conversationError, creationError, sendMessageError]);

    useEffect(() => {
        if (
            (!conversationState && creationSucceeded && createConversationResponse) ||
            (messageReplySucceeded && sendMessageResponse)
        ) {
            if (
                isConcertGraphqlValidationError(createConversationResponse?.createConversation) ||
                isConcertGraphqlValidationError(sendMessageResponse?.sendConversationMessage)
            ) {
                notificationContext?.showError(MESSAGE_NOT_SAVED);
                return;
            }

            notificationContext.showSuccess(MESSAGE_SUCCESSFULLY_SUBMITTED);
            history.push(`${ROUTES.MESSAGES}?${ROUTE_PARAMS.PATIENT_ID}=${patientId}`);
            return;
        }
        if (!conversationState && conversation) {
            setConversationState(conversation!.getConversation!.conversation!);
        }
    }, [conversation, creationSucceeded, messageReplySucceeded]);
    return (
        <div>
            <div className="app-container message-center-container">
                <div className="conversation-header-container">
                    <div>
                        <BreadCrumb model={getNavigationModel()} home={getHomeBreadcrumb()} />
                    </div>
                    {conversationState !== null && isReplyAllowed() && !showForm && (
                        <div className="conversation-btn reply-conversation">
                            <Button
                                icon="pi pi-plus"
                                className="p-button p-component p-button-primary p-menuitem-text"
                                data-testid="new-row"
                                label="Reply"
                                aria-label="Create new message"
                                onClick={() => setShowForm(true)}
                            />
                        </div>
                    )}
                </div>
                {isBusy() ? (
                    <Loader />
                ) : (
                    <ConversationViewer
                        conversation={conversationState}
                        messageSenderComponentProps={{
                            isNewConversation,
                            isFormVisible: showForm,
                            disabled: conversationState !== null && !isReplyAllowed(),
                            formValue: sendMessageFormData,
                            handleMessageSubmission,
                        }}
                    />
                )}
            </div>
        </div>
    );
};
