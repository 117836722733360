import { Button } from 'primereact/button';
import { ChangeEvent, FormEvent, FunctionComponent, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import * as QueryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { Editor, EditorTextChangeEvent } from 'primereact/editor';
import Quill from 'quill';
import Delta, { Op } from 'quill-delta';
import MagicUrl from 'quill-magic-url';
import { CrisisFooter } from '../appointments/common/crisis-footer.component';
import { ROUTE_PARAMS, ROUTES } from '../../constants';

Quill.register('modules/autoLink', MagicUrl);
export interface Episode {
    id: string;
    patientId: string | null;
    clinicianId: string | null;
}
export interface MessageSenderProps {
    isNewConversation: boolean;
    handleMessageSubmission: (formData: MessageForm) => void;
    isFormVisible: boolean;
    formValue: MessageForm | null;
    disabled: boolean;
}
export interface MessageForm {
    message: string;
    topic: string | undefined;
    clinicalNotes: string;
    episode: Episode | null;
    minutes: string | undefined;
    isValid: boolean;
}

export interface ConversationContext {
    episodeId: string;
    clinicianId: string;
    clinicianName?: string;
}
const modules = {
    autoLink: true,
    clipboard: {
        matchVisual: false,
    },
    keyboard: {
        bindings: {
            indent: null,
        },
    },
};

const TopicMaxLength = 100;
const MessageAndClinicalNoteMaxLength = 1000;

export const MessageSender: FunctionComponent<MessageSenderProps> = ({
    isNewConversation,
    handleMessageSubmission,
    isFormVisible,
    formValue,
    disabled,
}) => {
    const location = useLocation<{
        conversationContext: ConversationContext | null | undefined;
    }>();
    const conversationContext = location.state?.conversationContext as ConversationContext | null;
    const history = useHistory();
    const { patientId } = QueryString.parse(location.search);

    const editorRef = useRef<Editor | null>(null);
    const allowedElements = ['bold', 'italic', 'underline', 'link', 'list', 'list-item'];

    const handleEditorRef = (instance: Editor | null) => {
        if (instance) {
            editorRef.current = instance;
            const quill = instance.getQuill() as Quill;
            if (!quill) return;

            quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node: Node, delta: Delta) => {
                if (node.nodeName === 'IMG') return new Delta();

                const ops: Delta['ops'] = [];
                delta.ops.forEach((op: Op) => {
                    if (typeof op.insert === 'string') {
                        if (op.attributes) {
                            const filteredAttributes = Object.keys(op.attributes)
                                .filter((attr) => allowedElements.includes(attr))
                                .reduce((obj, key) => {
                                    obj[key] = op.attributes?.[key];
                                    return obj;
                                }, {});

                            ops.push({
                                insert: op.insert,
                                attributes: Object.keys(filteredAttributes).length > 0 ? filteredAttributes : undefined,
                            });
                        } else {
                            ops.push(op);
                        }
                    }
                });

                delta.ops = ops;
                return delta;
            });
        }
    };

    const cancelMessageSubmission = () => {
        history.push({
            pathname: ROUTES.MESSAGES,
            search: `?${ROUTE_PARAMS.PATIENT_ID}=${patientId}`,
            state: {
                refresh: true,
            },
        });
    };
    const [form, setForm] = useState<MessageForm>(
        formValue ??
            ({
                episode: {
                    id: conversationContext?.episodeId,
                    clinicianId: conversationContext?.clinicianId,
                },
            } as MessageForm),
    );
    const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

    const validateForm = (): boolean => {
        if (isNewConversation && isEmpty(form.topic)) return false;
        return !isEmpty(form.message);
    };

    const isEmpty = (value: string | undefined | null): boolean => {
        return !value || value.trim() === '';
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsFormSubmitted(true);
        const validatedForm = { ...form, isValid: validateForm() };
        handleMessageSubmission(validatedForm);
    };
    const handleEditorChange = (e: EditorTextChangeEvent, fieldName: string) => {
        const { htmlValue, textValue } = e;
        const isTextEmpty = (textValue && textValue.trim()) === '';
        setForm({ ...form, [fieldName]: isTextEmpty ? '' : htmlValue ?? '' });
    };
    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };
    const renderForm = (): JSX.Element[] => {
        const formData: JSX.Element[] = [];
        if (isNewConversation) {
            formData.push(
                <>
                    <div className="p-inputgroup flex-1">
                        <InputText
                            value={conversationContext?.clinicianName}
                            placeholder="Owner"
                            name="owner"
                            readOnly
                            disabled
                        />
                    </div>
                    <div className="p-inputgroup flex-1">
                        <InputText
                            value={form?.topic}
                            placeholder={`Topic (up to ${TopicMaxLength} characters)`}
                            name="topic"
                            onChange={handleInputChange}
                            maxLength={TopicMaxLength}
                            invalid={isFormSubmitted && isEmpty(form.topic)}
                        />
                    </div>
                </>,
            );
        }
        formData.push(
            <>
                <div className="p-inputgroup flex-1">
                    <Editor
                        ref={handleEditorRef}
                        value={form.message}
                        name="message"
                        placeholder={`Message (up to ${MessageAndClinicalNoteMaxLength} characters)`}
                        aria-label="message"
                        data-testid="message"
                        showHeader={false}
                        onTextChange={(e: EditorTextChangeEvent) => handleEditorChange(e, 'message')}
                        modules={modules}
                        maxLength={MessageAndClinicalNoteMaxLength}
                        className={classNames({
                            'p-invalid': isFormSubmitted && isEmpty(form.message),
                        })}
                    />
                </div>
                <div className="p-inputgroup flex-1 message-send-buton">
                    <Button
                        disabled={disabled}
                        className="p-button-secondary"
                        label="Submit"
                        aria-label={isNewConversation ? 'Submit new topic' : 'Submit message'}
                        type="submit"
                    />
                    <Button
                        disabled={disabled}
                        className="p-button-link"
                        label="Cancel"
                        aria-label={isNewConversation ? 'Cancel topic creation' : 'Cancel message'}
                        type="button"
                        onClick={cancelMessageSubmission}
                    />
                </div>
                <div className="p-inputgroup flex-1">
                    <CrisisFooter />
                </div>
            </>,
        );
        return formData;
    };

    return (
        <div className="message-sender-container">
            <form className={isFormVisible ? 'visible-form' : ''} onSubmit={handleSubmit}>
                {isFormVisible && renderForm()}
            </form>
        </div>
    );
};
