import { createContext } from 'react';
import { Auth, Amplify } from 'aws-amplify';
import { DEFAULT_GRAPHQL_URL } from './services/graphql/constants';

export interface SFConfig {
    loginUrl: string;
    redirectUri: string;
    clientId: string;
}

export interface AppConfig {
    apiUrl: string;
    sfSettings: SFConfig;
    authSettings: AuthSettings;
    graphQLSettings: GraphQLSettings;
}

export interface GraphQLSettings {
    graphQLUrl: string;
    apiKey?: string;
}

export interface AuthSettings {
    userPoolId: string;
    region: string;
    userPoolWebClientId: string;
    identityPoolId: string;
}
export interface DatadogSettings {
    applicationId: string;
    clientToken: string;
    site: string;
    service: string;
    env: string;
    // Specify a version number to identify the deployed version of your application in Datadog
    version: string;
    sessionSampleRate: string;
    sessionReplaySampleRate: string;
    trackUserInteractions: boolean;
}

export const getDatadogConfig = (): DatadogSettings => {
    const dataDogRum = window.config.datadogSettings as DatadogSettings;
    return dataDogRum;
};

/** ISO 8601 Timestamp Format: [YYYY]-[MM]-[dd]T[HH]:[mm]:[ss].[sss][Z/±hh:mm] */
export type ISO8601Timestamp = string | null;

export interface MaintenanceRoute {
    route: string;
    message: string;
}
export interface MaintenanceSettings {
    message: string;
    startDateTime: ISO8601Timestamp;
    endDateTime: ISO8601Timestamp;
    routeMessages: string;
}

export interface ApplicationSettings {
    rescheduleStartTimeBoundary: string;
    supportPhoneNumber: string;
    maintenanceSettings: MaintenanceSettings;
}

export const getConfig = (authSettings: AuthSettings): any => {
    const graphQLConfig: GraphQLSettings = getGraphQLConfig();
    Amplify.configure({
        Auth: authSettings,
        aws_appsync_graphqlEndpoint: graphQLConfig?.graphQLUrl ?? DEFAULT_GRAPHQL_URL,
        aws_appsync_region: authSettings.region,
        aws_appsync_authenticationType: 'AWS_IAM',
    });
    // You can get the current config object
    const currentConfig = Auth.configure();
    return currentConfig;
};

export const getAuthSettings = (): AuthSettings => window.config.authSettings as AuthSettings;

export const getGraphQLConfig = (): GraphQLSettings => {
    const graphQLSettings = window?.config?.graphQLSettings as GraphQLSettings;
    return graphQLSettings;
};

export const getAppSettings = (): ApplicationSettings => {
    return (
        window.config?.applicationSettings ?? {
            rescheduleStartTimeBoundary: '00:30:00',
            supportPhoneNumber: '(855) 641-6060',
            maintenanceSettings: {
                message: '',
                startDateTime: '',
                endDateTime: '',
            },
        }
    );
};

export const isDevelopment = (): boolean => process.env.NODE_ENV === 'development';

export const ConfigContext: null | any = createContext(null);
